<template lang="pug">
WelcomePage.singin
	template(#logo)
		img.logo(src="~@/assets/img/nestle/cat-logo-horizontal.png")
	template(#title)
		h1.title
			| Система мониторинга
			br
			| рынка электронной торговли
	template(#bottom)
		Link.singin__btn(:to="{name: 'Request'}" unstyled)
			// Button(type="violet-outline") Заявка на доступ
	template(#form)
		SignInForm

</template>

<script>
import Button from '@/components/Button/Button.vue'
import Link from '@/components/Link/Link.vue'
import WelcomePage from '@/components/Nestle/WelcomePage/WelcomePage.vue'
import EllipseItem from '@/components/Elements/EllipseItem.vue'
import SignInForm from '@/components/Nestle/SignInForm/SignInForm.vue'

import { mapActions } from 'vuex'

export default {
	components: {
		Button,
		Link,
		WelcomePage,
		EllipseItem,
		SignInForm
	},
	mounted() {
		this.logout({ silent: true })
	},
	methods: {
		...mapActions('app', ['logout']),
	},
}
</script>

<style lang="scss" scoped>
.singin {
	position: relative;
	@include radialBg(#DFD3F2);

	&__btn {
		margin-top: 30px;
		margin-left: 40px;
	}
}
.logo {
	width: 200px;
}
.title {
	margin-top: 8px;
	font-size: 28px;	
}
</style>
